define('modules/basemodule/controllers/EntityListController',[
	'modules/module',
	'services/EntityCrudService',
	'services/NotificationService',
	'services/NavigationService',
	'services/UserService',
	'directives/entityTable/ngEntityTable',
], function (module) {
	'use strict';
	module.controller('EntityListController', [
		'$scope',
		'$rootScope',
		'$q',
		'$injector',
		'$location',
		'EntityCrudService',
		'NotificationService',
		'NavigationService',
		'UserService',
		'InstallationService',
		ListController,
	]);
});

function ListController(
	$scope,
	$rootScope,
	$q,
	$injector,
	$location,
	EntityCrudService,
	NotificationService,
	NavigationService,
	UserService,
	InstallationService
) {
	$scope.isClientNumber = InstallationService.isClientNumber;
	$scope.requestPlugins = InstallationService.requestPlugins;
	$scope.isPluginInstalled = InstallationService.isPluginInstalled;

	$scope.CONTROLLER_ID = 'ListController';

	$scope.initialization = $q.defer();
	$scope.entityName = '';
	$scope.search = {};
	$scope.tableOptions = {};
	$scope.selected = {
		items: [],
	};
	$scope.headline = NavigationService.getActiveSubitem().label;
	$scope.single = NavigationService.getActiveSubitem().single;

	$scope.state = {
		hideCreateAndDeleteButtons: false,
		hideDeleteButton: false,
		hideUpdateButton: false,
		ids: [],
		requireSuperuserForCreateAndDelete: false,
		requireSuperuserForUpdate: false,
	};

	$scope.clearIDs = () => {
		$scope.state.ids = [];
	};

	$scope.initialization.promise.then(function () {
		$scope.$on('filtersChanges', function (e, filters, sorting, id, filterProductType) {
			var params = [];
			for (var obj in filters) {
				params.push({
					name: 'filter[' + obj + ']',
					value: filters[obj],
				});
			}

			params.push({
				name: 'filter[order]',
				value: sorting,
			});

			if (filterProductType) {
				params.push({
					name: 'filterProductType',
					value: filterProductType,
				});
			}

			if (!!$scope.localParams) {
				params = params.concat($scope.localParams);
			}

			$scope.entityService.setAdditionalParams(params);
			$scope.getEntities().then(function (response) {
				$scope.setTableData(response);
			});
		});
	});

	$scope.$on('$destroy', function () {
		//will be called on controller destroy
		$scope.unregisterShopChanged();
	});

	$scope.unregisterShopChanged = $rootScope.$on('ShopChanged', function () {
		$scope.init($scope.entityName);
		$scope.getEntities().then(function (response) {
			$scope.setTableData(response);
		});
	});

	$scope.init = function (entity, entityService = null) {
		setHideButtons();

		if (!!entityService) {
			// If a service is passed use it as the controllers entity service
			$scope.entityService = entityService;
			if ($location.hash()) {
				$scope.entityService.setCurrentPage($location.hash()).then(function (response) {
					$scope.setTableData(response);
				});
			}
		} else if (typeof entity === 'string') {
			// If only an entity type is passed get a generic service instance
			$scope.entityService = EntityCrudService.getEntityInstance(entity);
			if ($location.hash()) {
				$scope.entityService.setCurrentPage($location.hash()).then(function (response) {
					$scope.setTableData(response);
				});
			}
		} else {
			throw 'You have to pass either an entity type or service to the controller.';
		}

		$scope.entityName = $scope.entityService.getType();
		$scope.getEntities = $scope.entityService.findAll;

		//reset query if there is one
		$scope.entityService.setQuery({}, false).then(function (response) {
			$scope.setTableData(response);
		});

		$scope.entityName = $scope.entityService.getType();

		if ($scope.entityService.getPageSize() == null) {
			$scope.pageSize = 10;
			$scope.setPageSize(false);
		}

		$scope.initialization.resolve();
		return $scope.initialization.promise;
	};

	$scope.$watch(
		function () {
			return $location.hash();
		},
		function (value) {
			$scope.entityService.setCurrentPage($location.hash()).then(function (response) {
				$scope.setTableData(response);
			});
		}
	);

	$scope.setTableData = function (response) {
		$scope.pageSize = $scope.entityService.getPageSize();
		$scope.totalItems = $scope.entityService.getTotalItems();
		$scope.totalPages = $scope.entityService.getTotalPages();

		if ($scope.totalPages === 0) {
			//If there are no items the total pages size should be displayed as 1
			$scope.totalPages = 1;
		}

		$scope.currentPage = $scope.entityService.getCurrentPage();
		$scope.items = $scope.entityService.getAll();
		$scope.$broadcast('tableDataRefreshed');
	};

	$scope.getRealCurrentPage = function () {
		var cur = angular.copy(parseInt($scope.currentPage));
		cur = cur === 0 ? cur : cur - 1;
		return cur;
	};

	$scope.showCurrentlyDisplayed = function () {
		let from = 0;
		let to = 0;

		if ($scope.items && $scope.items.length > 0) {
			from = parseInt($scope.pageSize) * parseInt($scope.getRealCurrentPage());
			to = Math.min(from + parseInt($scope.pageSize), $scope.totalItems);

			// Because 'from' starts at 0
			from++;
		}

		return from + ' - ' + to;
	};

	$scope.setPageSize = function (findAll) {
		$scope.entityService.setPageSize($scope.pageSize, findAll).then(function (response) {
			$scope.setTableData(response);
		});
	};

	$scope.setPage = function (currentPage) {
		if (currentPage) {
			$scope.currentPage = currentPage;
		} else {
			$location.hash($scope.getRealCurrentPage());
		}

		$scope.entityService.setCurrentPage($scope.getRealCurrentPage()).then(function (response) {
			$scope.setTableData(response);
		});
	};

	$scope.update = function (item) {
		$scope.$broadcast('beforeUpdate');
		NavigationService.changeState($scope.formState, item.id);
	};

	$scope.create = function () {
		$scope.$broadcast('beforeCreate');
		$scope.entityService.clear().then(function (response) {
			$scope.setTableData(response);
		});
		NavigationService.changeState($scope.formState);
	};

	$scope.delete = function () {
		if ($scope.selected.items <= 0) {
			return;
		}

		NotificationService.confirm({
			statusText: 'Wollen sie die selektierten Objekte wirklich löschen?',
			msg: '' /*msgs.join('; ')*/,
		}).then(
			function () {
				if ($scope.selected.items.length == 1) {
					var deleted = [];
					$scope.selected.items.forEach(function (item) {
						deleted.push($scope.entityService.delete(item.id));
					});
					$q.all(deleted).then(function () {
						$scope.selected.items = [];
						$scope.setTableData();
					});
				} else {
					$scope.selected.items.forEach(function (item) {
						$scope.state.ids.push(item.id);
					});
					$scope.entityService.bulkDelete($scope.state.ids).then(function () {
						$scope.selected.items = [];
						$scope.setTableData();
					});
					$scope.clearIDs();
				}
				$rootScope.$emit('DeleteTableData');
				$scope.$emit('NotifyWaitingMessageCustomerGroup');
			},
			function () {}
		);
	};

	const setHideButtons = () => {
		const isSuperuser = UserService.isSuperuser();

		if ($scope.state.requireSuperuserForCreateAndDelete) {
			$scope.state.hideCreateAndDeleteButtons = !isSuperuser;
		} else {
			$scope.state.hideCreateAndDeleteButtons = false;
		}

		if ($scope.state.requireSuperuserForDelete) {
			$scope.state.hideDeleteButton = !isSuperuser;
		} else {
			$scope.state.hideDeleteButton = false;
		}

		if ($scope.state.requireSuperuserForUpdate) {
			$scope.state.hideUpdateButton = !isSuperuser;
		} else {
			$scope.state.hideUpdateButton = false;
		}
	};
}
;
