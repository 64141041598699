define('services/NavigationService',['modules/module', 'services/StateFaderService', 'services/UserService', 'settings'], function (module) {
	'use strict';
	return module.factory('NavigationService', [
		'StateFader',
		'MENU_LEFT',
		'MENU_RIGHT',
		'UserService',
		NavigationService,
	]);
});

function NavigationService(StateFader, MENU_LEFT, MENU_RIGHT, UserService) {
	var _active = undefined,
		_activeSubitem = undefined,
		_filteredLeftMenuItems = [], // Filtered by permissions
		_filteredRightMenuItems = [],
		_filteredMenuItems = []; // Both left and right

	var _setActiveItemByStateName = function (name) {
		for (var counter = 0; counter < _filteredMenuItems.length; counter++) {
			var menuItem = _filteredMenuItems[counter];
			if (menuItem.subMenu) {
				for (var j = 0; j < menuItem.subMenu.length; j++) {
					var subItem = menuItem.subMenu[j];
					if (subItem.states.indexOf(name) != -1) {
						_active = menuItem;
						_activeSubitem = subItem;
						subItem.active = true;
						return;
					}
				}
			}
		}
	};

	var _setActiveItem = function (item, subItem) {
		// Deactivate all menu items
		_filteredMenuItems.forEach((menuItem) => {
			menuItem.active = false;

			if (menuItem.subMenu) {
				menuItem.subMenu.forEach((subMenuItem) => (subMenuItem.active = false));
			}
		});

		_active = item;
		_active.active = true;
		if (subItem === undefined) {
			_active.subMenu[0].active = true;
			_activeSubitem = _active.subMenu[0];
			StateFader.go(_active.subMenu[0].states[0]);
		} else {
			_setActiveSubItem(subItem);
			_activeSubitem = subItem;
		}
	};

	var _setActiveSubItem = function (item) {
		_active.subMenu.forEach(function (subItem) {
			if (subItem.states.indexOf(item.states[0]) != -1) {
				subItem.active = true;
				StateFader.go(subItem.states[0]);
			}
		});
	};

	var _changeState = function (state, id) {
		var finished = false;
		_filteredMenuItems.forEach(function (menuItem) {
			if (menuItem.subMenu !== undefined && !finished) {
				for (var i = 0; i < menuItem.subMenu.length; i++) {
					if (menuItem.subMenu[i].states.indexOf(state) != -1) {
						_setActiveItem(menuItem, menuItem.subMenu[i]);
						finished = true;
					}
				}
			}
		});

		StateFader.go(state, id);

		return false;
	};

	var _changeStateWithParams = function (state, obj) {
		var finished = false;
		_filteredMenuItems.forEach(function (menuItem) {
			if (menuItem.subMenu !== undefined && !finished) {
				for (var i = 0; i < menuItem.subMenu.length; i++) {
					if (menuItem.subMenu[i].states.indexOf(state) != -1) {
						_setActiveItem(menuItem, menuItem.subMenu[i]);
						finished = true;
					}
				}
			}
		});
		StateFader.goWithObject(state, obj);

		return false;
	};

	const filterMenuItemsByPermissions = (menuItems) => {
		if (UserService.isSuperuser()) {
			return menuItems;
		}

		// Make a deep copy to avoid modifying source of menu items
		let filteredMenuItems = angular.copy(menuItems, []);

		return filteredMenuItems.filter((menuItem) => {
			if (menuItem.requiredRole && !UserService.doesUserHaveARole(menuItem.requiredRole)) {
				return false;
			}

			if (menuItem.subMenu) {
				menuItem.subMenu = menuItem.subMenu.filter((subMenuItem) => {
					return !subMenuItem.requiredRole || UserService.doesUserHaveARole(subMenuItem.requiredRole);
				});
			}

			return true;
		});
	};

	const setFilteredMenuItems = () => {
		_filteredLeftMenuItems = MENU_LEFT;
		_filteredRightMenuItems = MENU_RIGHT;
		_filteredMenuItems = _filteredLeftMenuItems.concat(_filteredRightMenuItems);
	};

	setFilteredMenuItems();

	return {
		getItems: function () {
			return _filteredLeftMenuItems;
		},
		getItemByID: (label) => {
			return _filteredLeftMenuItems.find((item) => item.identifier === label);
		},
		getItemsRight: function () {
			return _filteredRightMenuItems;
		},
		getActiveItem: function () {
			return _active;
		},
		getActiveSubitem: function () {
			return _activeSubitem;
		},
		setActiveItem: function (item) {
			_setActiveItem(item);
		},
		setActiveItemByStateName: function (name) {
			_setActiveItemByStateName(name);
		},
		setActiveSubItem: function (item) {
			_setActiveSubItem(item);
		},
		setFilteredMenuItems: setFilteredMenuItems,
		changeState: function (state, id) {
			_changeState(state, id);
		},
		changeStateWithObject: function (state, obj) {
			_changeStateWithParams(state, obj);
		},
		getSaveOrCreate: function () {
			if (window.location.href.slice(-1) == '/' && _active.label != 'System') {
				return 'ACTIONS.CREATE';
			} else if (parseInt(window.location.href.slice(-1)) > 0 && _active.label != 'System') {
				return 'ACTIONS.EDIT';
			} else {
				return;
			}
		},
	};
}
;
