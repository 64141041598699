define('routes',[
	'modules/navigation/controllers/NavigationController',
	'modules/navigation/controllers/FooterController',
	'modules/econ/controllers/EconCampaignController',
	'modules/econ/controllers/EconCampaignFormController',
	'modules/econ/controllers/EconRecipientController',
	'modules/econ/controllers/EconRecipientFormController',
	'modules/econ/controllers/EconMailtemplateController',
	'modules/econ/controllers/EconMailtemplateFormController',
	'modules/econ/controllers/EconNewsletterController',
	'modules/econ/controllers/EconNewsletterFormController',
	'modules/econ/controllers/EconRecipientListController',
	'modules/econ/controllers/EconRecipientListFormController',
	'modules/econ/controllers/EconAutomationController',
	'modules/econ/controllers/EconAutomationFormController',
	'modules/econ/controllers/EconDashboardController',
	'modules/settings/user/UserController',
	'modules/settings/user/UserFormController',
	'modules/settings/econ/EconSettingsController',
	'modules/settings/importexportcsv/ImportExportCSVController',
	'modules/models',
	'modules/home/controllers/HomeController',
	'modules/login/controllers/LoginController',
	'modules/changePassword/controllers/ChangePasswordController',
	'modules/image/controllers/ImageController',
	'modules/image/controllers/ImageUploadController',
	'modules/notification/controllers/NotificationController',
	'modules/navigation/controllers/NavigationController',
	'modules/home/controllers/ReportController',
	'modules/home/controllers/SeminarDetailReportController',
], function (models) {
	'use strict';

	return {
		initRoutes: function ($urlRouterProvider, $stateProvider) {
			$urlRouterProvider.otherwise('/');

			$stateProvider

				//---===NEW===---
				.state('root.econSettings', {
					url: '/econ-settings',
					views: {
						'content@': {
							controller: 'EconSettingsController',
							templateUrl: 'template/econ/partials/settings.html',
						},
					},
				})
				.state('root', {
					url: '',
					abstract: true,
					views: {
						'navbar@': {
							controller: 'NavigationController',
							templateUrl: 'template/navigation/navbar.html',
						},
						'footer@': {
							controller: 'FooterController',
							templateUrl: 'template/navigation/footer.html',
						},
						'submenu@': {
							controller: 'NavigationController',
							templateUrl: 'template/navigation/submenu.html',
						},
					},
				})
				.state('root.econDashboard', {
					url: '/econ/dashboard',
					views: {
						'content@': {
							controller: 'EconDashboardController',
							templateUrl: 'template/econ/partials/dashboard.html',
						},
					},
				})
				.state('root.econRecipients', {
					url: '/econ/recipients',
					views: {
						'content@': {
							controller: 'EconRecipientController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econRecipient', {
					url: '/econ/recipient/:id',
					views: {
						'content@': {
							controller: 'EconRecipientFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econRecipientLists', {
					url: '/econ/recipient-lists',
					views: {
						'content@': {
							controller: 'EconRecipientListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econRecipientList', {
					url: '/econ/recipient-list/:id',
					views: {
						'content@': {
							controller: 'EconRecipientListFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econMailtemplates', {
					url: '/econ/mailtemplates',
					views: {
						'content@': {
							controller: 'EconMailtemplateController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econNewsletters', {
					url: '/econ/newsletters',
					views: {
						'content@': {
							controller: 'EconNewsletterController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econNewsletter', {
					url: '/econ/newsletter/:id',
					views: {
						'content@': {
							controller: 'EconNewsletterFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econAutomations', {
					url: '/econ/automations',
					views: {
						'content@': {
							controller: 'EconAutomationController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econAutomation', {
					url: '/econ/automation/:id',
					views: {
						'content@': {
							controller: 'EconAutomationFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econMailtemplate', {
					url: '/econ/mailtemplate/:id',
					views: {
						'content@': {
							controller: 'EconMailtemplateFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econCampaigns', {
					url: '/econ/campaigns',
					views: {
						'content@': {
							controller: 'EconCampaignController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econCampaign', {
					url: '/econ/campaign/:id',
					views: {
						'content@': {
							controller: 'EconCampaignFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.templatePage', {
					url: '/template-page/:id',
					views: {
						'content@': {
							controller: 'TemplatePageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.templatePages', {
					url: '/template-pages',
					views: {
						'content@': {
							controller: 'TemplatePageController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.users', {
					url: '/users',
					views: {
						'content@': {
							controller: 'UserController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.importexportcsv', {
					url: '/importexportcsv',
					views: {
						'content@': {
							controller: 'ImportExportCSVController',
							templateUrl: 'template/settings/importExport/import-export-csv.html',
						},
					},
				})
				.state('root.user', {
					url: '/user/:id',
					views: {
						'content@': {
							controller: 'UserFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.logs', {
					url: '/logs',
					views: {
						'content@': {
							controller: 'LogController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.smtp', {
					url: '/smtp',
					views: {
						'content@': {
							controller: 'SmtpController',
							templateUrl: 'template/settings/smtp/form-smtp.html',
						},
					},
				})
				.state('root.nodes', {
					url: '/nodes',
					views: {
						'content@': {
							controller: 'NodeController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.node', {
					url: '/node/:id',
					views: {
						'content@': {
							controller: 'NodeFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				//---===OLD===---
				.state('root.home', {
					url: '/',
					views: {
						'content@': {
							controller: 'EconDashboardController',
							templateUrl: 'template/econ/partials/dashboard.html',
						},
					},
				})
				.state('root.resetPassword', {
					url: '/resetpassword/:resetKey',
					views: {
						'content@': {
							controller: 'ChangePasswordController',
							templateUrl: 'template/login/login.html',
						},
					},
				})
				.state('root.login', {
					url: '/login',
					views: {
						'content@': {
							controller: 'LoginController',
							templateUrl: 'template/login/login.html',
						},
					},
				})
				.state('root.images', {
					url: '/images',
					views: {
						'content@': {
							controller: 'ImageUploadController',
							templateUrl: 'template/image/upload.html',
						},
					},
				})
				.state('about', {
					url: '/about',
					templateUrl: '/nix.html',
					controller: function ($scope) {
						$scope.nix = 'ABOUT DIALOG';
						$scope.show = !$scope.show;
					},
				});
		},
	};
});

