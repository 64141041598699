define('directives/entityTable/ngEntityTable',[
	'app',
	'settings',
	'services/DebounceService',
	'services/EntityFilterService',
	'services/InstallationService',
	'services/UserService',
	'services/SessionValuesService',
], function (app) {
	'use strict';
	app.directive('ngEntityTable', ['BASE_DIRECTIVES_PATH', NgEntityTable]);
});
function NgEntityTable(BASE_DIRECTIVES_PATH) {
	return {
		scope: {
			id: '=?',
			fields: '=',
			delay: '=?',
			selected: '=',
			items: '=',
			totalItems: '=?', // count of total items
			onItemClick: '=',
			customStyle: '=',
			preItemClick: '=',
			postItemClick: '=',
			optionalFields: '=',
			sortable: '=?',
			persistFilters: '=?',
			showTimeframe: '=?',
		},
		templateUrl: BASE_DIRECTIVES_PATH + '/entityTable/views/entity-table.html',
		controller: [
			'$scope',
			'$rootScope',
			'$timeout',
			'DebounceService',
			'EntityFilterService',
			'NavigationService',
			'InstallationService',
			'SessionValuesService',
			'UserService',
			NgEntityTableController,
		],
	};
}

function NgEntityTableController(
	$scope,
	$rootScope,
	$timeout,
	DebounceService,
	EntityFilterService,
	NavigationService,
	InstallationService,
	SessionValuesService,
	UserService
) {
	$scope.defaults = {
		delay: 500,
		minchars: 3,
	};

	$scope.state = {
		allSelected: false,
		allSelectedOnAllPages: false,
		filterProductType: '',
	};

	$scope.filters = {};
	$scope.sortDirection = 'ASC';

	for (var i = 0; i < $scope.fields.length; i++) {
		if ($scope.fields[i].attribute !== undefined && !$scope.fields[i].orderDisabled) {
			$scope.sortField = $scope.fields[i].attribute;
			break;
		}
	}

	if ($scope.fields[0] != undefined) {
		if ($scope.fields[0].attribute === 'createdAt') {
			$scope.sortDirection = 'DESC';
		} else if ($scope.fields[0].attribute === 'position') {
			$scope.sortDirection = 'ASC';
			$scope.sortField = $scope.fields[0].attribute;
		}
	}

	if ($scope.fields[1] !== undefined) {
		if ($scope.fields[1].attribute === 'orderDate') {
			$scope.sortDirection = 'DESC';
			$scope.sortField = $scope.fields[1].attribute;
		}
	}

	if (isNaN(parseInt($scope.delay))) {
		$scope.delay = $scope.defaults.delay;
	}

	$scope.initFilters = function () {
		$scope.id = EntityFilterService.registerTable(
			$scope.id,
			$scope.filters,
			$scope.sortField,
			$scope.sortDirection,
			$scope.persistFilters
		);
		$scope.filters = EntityFilterService.getFilterSet($scope.id);
		$scope.sorting = EntityFilterService.getSorting($scope.id);

		$scope.$emit('filtersChanges', $scope.filters, $scope.sorting, $scope.id);
	};

	$scope.initFilters();

	$scope.selectionChange = function (item) {
		if (item == undefined) {
			return;
		}

		if (item.selected) {
			$scope.addSelectedItem(item);
		} else {
			$scope.removeSelectedItem(item);
		}

		$scope.state.allSelected = $scope.areAllItemsSelected();
		$scope.setAllSelectedOnAllPages(false);
	};

	$scope.areAllItemsSelected = () => {
		if (!$scope.items) {
			return false;
		}

		for (const item of $scope.items) {
			if (!item.selected) {
				return false;
			}
		}

		return true;
	};

	$scope.setAllSelectedOnAllPages = (allSelectedOnAllPages) => {
		$scope.state.allSelectedOnAllPages = allSelectedOnAllPages;

		// to access this variable in parent. Use event instead?
		if ($scope.$parent && $scope.$parent.selected) {
			$scope.$parent.selected.allSelectedOnAllPages = allSelectedOnAllPages;
		}
	};

	$scope.keyEvent = function (keyEvent, item) {
		if (keyEvent.which === 13) {
			$rootScope.$emit('metadataUpdate', item);
		}
	};

	/*
     Modal Dialog functions
     */
	$scope.onSuccess = function (result) {};

	$scope.onCancel = function () {};

	$scope.dialogImageController = function ($scope, dataToPass, $mdDialog) {
		$scope.data = dataToPass;
		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	};

	$scope.onSelectAllClick = function () {
		if (!$scope.items) {
			return;
		}

		$scope.setAllSelectedOnAllPages(false);

		$scope.items.forEach(function (item) {
			if ($scope.state.allSelected) {
				$scope.addSelectedItem(item);
			} else {
				$scope.removeSelectedItem(item);
			}
		});
	};

	$scope.setSorting = function (field) {
		if (field.orderDisabled != true) {
			if (field.attribute === $scope.sortField) {
				$scope.toggleDirection();
			} else {
				$scope.sortDirection = 'ASC';
			}
			$scope.sortField = field.attribute;
			$scope.filter(field);
		}
	};

	$scope.toggleDirection = function () {
		if ($scope.sortDirection === 'ASC') {
			$scope.sortDirection = 'DESC';
		} else {
			$scope.sortDirection = 'ASC';
		}
	};

	$scope.getClass = function (field) {
		if ($scope.sortField === field.attribute) {
			return $scope.sortDirection === 'ASC' ? 'fa-chevron-down sort-indicator' : 'fa-chevron-up sort-indicator';
		}
	};

	$scope.filter = function (field) {
		$scope.sortField = field.attribute;
		var sorting = '';
		sorting = $scope.sortField + ' ' + $scope.sortDirection;
		if ($scope.timeout !== undefined) {
			$timeout.cancel($scope.timeout);
		}
		$scope.timeout = $timeout($scope.emitChanges, $scope.delay, true, sorting);
	};

	$scope.emitChanges = DebounceService.debounce((sorting) => {
		EntityFilterService.updateFilterSet($scope.id, $scope.filters);
		EntityFilterService.updateSorting($scope.id, sorting);
		$scope.$emit('filtersChanges', $scope.filters, sorting, $scope.id, $scope.state.filterProductType);

		$scope.state.allSelected = false;
		$scope.setAllSelectedOnAllPages(false);
		$scope.timeout = undefined;
	}, $scope.delay);

	$scope.productTypeSelect = () => {
		$scope.emitChanges('productType ASC');
	};

	$scope.getItemTemplatePath = function (field, item) {
		if (field.getter !== undefined) {
			return BASE_DIRECTIVES_PATH + '/dataTable/views/partials/field-item.html';
		}
		if (field.templateUrl !== undefined) {
			return field.templateUrl;
		}
	};

	$scope.isTimestamp = function (val) {
		if (typeof val !== 'string') {
			return false;
		}
		return val.search(/\d*-\d*-\d*T\d*:\d*:\d*.\d*\+\d*/i) === 0;
	};

	$scope.onItemClickInternal = function (item, field) {
		if (field.attribute === 'position') {
			return;
		}

		if ($scope.preItemClick !== undefined) {
			if (!$scope.preItemClick(item, field)) {
				return;
			}
		}

		if ($scope.onItemClick) {
			$scope.onItemClick(item, field);
		}

		if ($scope.postItemClick !== undefined) {
			$scope.postItemClick(item, field);
		}
	};

	$scope.isChecked = function (arg) {
		if ($scope.selected == undefined) {
			return;
		}
		if (arg.item == undefined) {
			return;
		}
		for (var i = 0; i < $scope.selected.length; i++) {
			var item = $scope.selected[i];
			if (item.id == arg.item.id) {
				item.selected = true;
				return true;
			}
		}
		return false;
	};

	$scope.addSelectedItem = function (item) {
		var containsItem = false;
		$scope.selected.forEach(function (alreadySelectedItem) {
			if (item.id == alreadySelectedItem.id) {
				containsItem = true;
			}
		});

		if (!containsItem) {
			item.newSelected = true;
			item.selected = true;
			$scope.selected.push(item);
		}
	};

	$scope.removeSelectedItem = function (item) {
		if ($scope.selected !== undefined) {
			$scope.selected.forEach(function (alreadySelectedItem, key) {
				if (item.id == alreadySelectedItem.id) {
					item.selected = false;
					$scope.selected.splice(key, 1);
				}
			});
		}
	};

	$scope.addField = function (field) {
		$scope.fields.push(field);
		for (var i = 0; i < $scope.optionalFields.length; i++) {
			if (field.id === $scope.optionalFields[i].id) {
				$scope.optionalFields.splice(i, 1);
			}
		}
	};

	$scope.removeField = function (field) {
		$scope.optionalFields.push(field);
		for (var i = 0; i < $scope.fields.length; i++) {
			if (field.id === $scope.fields[i].id) {
				$scope.fields.splice(i, 1);
			}
		}
	};

	$rootScope.$on('$suggestSearchList', function (event, suggestList, attribute) {
		for (var i = 0; i < $scope.fields.length; i++) {
			if ($scope.fields[i].suggestEnabled && $scope.fields[i].attribute === attribute) {
				$scope.fields[i].list = suggestList;
			}
		}
	});

	/* for sugggest */
	$scope.suggestSearch = function (query, field) {
		var res = field.list.filter(function (obj) {
			return obj.name.toLowerCase().indexOf(query) != -1;
		});
		return res;
	};

	$scope.filterSuggest = function (suggestObj, field) {
		if (suggestObj) {
			$scope.filters[field.attribute] = suggestObj.name;
		} else {
			$scope.filters[field.attribute] = '';
		}
		$scope.filter(field);
	};

	$scope.changeState = function (state, id, $event) {
		$event.stopPropagation();
		NavigationService.changeState(state, id);
	};

	/* suggest end */

	$rootScope.$on('DeleteTableData', function () {
		$scope.selected.forEach(function (item) {
			$scope.removeSelectedItem(item);
		});
		$scope.selected = [];
	});

	if ($scope.sortable == undefined) {
		$scope.sortable = false;
	} else {
		$scope.sortableOptions = {
			'ui-floating': true,
			axis: 'y',
			stop: function (e, ui) {
				for (var index in $scope.items) {
					$scope.items[index].position = parseInt(currentPage) * 10 + parseInt(index);
				}
			},
		};

		//$scope.filter($scope.fields[0]);
	}

	const deregisterWatchItems = $scope.$watch('items', (newValue, oldValue) => {
		$scope.state.allSelected = false;
		$scope.setAllSelectedOnAllPages(false);
	});

	$scope.$on('$destroy', () => {
		deregisterWatchItems();
	});

	$scope.requestPlugins = InstallationService.requestPlugins;
	$scope.isPluginInstalled = InstallationService.isPluginInstalled;
	$scope.requestPlugins(function () {
		function createReportURL(shopId, token, from, to, all) {
			let url = `/api/v1/plugin/msf-report/download`;

			if (all) {
				url += '-all';
			}

			url += `?shopId=${shopId}&Authorization=${token}`;

			if (!!from && !!from.length) {
				url += `&from=${from}`;
			}
			if (!!to && !!to.length) {
				url += `&to=${to}`;
			}
			return url;
		}
	});
}
;
