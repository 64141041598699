define('directives/fileModel/FileModel',['app'], function (app) {
	'use strict';
	app.directive('fileModel', [
		'$parse',
		function ($parse) {
			return {
				restrict: 'A',
				link: function (scope, element, attrs) {
					// Save the file value to scope when it changes
					var variableName = attrs.fileModel;

					element.on('change', function () {
						scope.$apply(function () {
							//fixed to work in ExportRecipientsCSV. Could not be working in some other cases, need to be checked.
							scope.$parent[variableName] = element[0].files[0];
							console.log('Scope');
						});
					});
				},
			};
		},
	]);
});

