define('modules/navigation/controllers/NavigationController',[
	'modules/module',
	'services/NavigationService',
	'services/UserService',
	'services/NotificationService',
	'bootstrap',
	'directives/LanguageSwitcher/LanguageSwitcher',
], function (module) {
	'use strict';
	module.controller('NavigationController', [
		'$rootScope',
		'$q',
		'$scope',
		'$state',
		'NavigationService',
		'UserService',
		'ModelFactory',
		'NotificationService',
		NavigationController,
	]);
});
function NavigationController(
	$rootScope,
	$q,
	$scope,
	$state,
	NavigationService,
	UserService,
	ModelFactory,
	NotificationService
) {
	$scope.isCollapsed = false;
	$scope.showNavigation = true;
	$scope.navbar = NavigationService;
	$scope.stateName = $state.current.name;
	$scope.navbar.setActiveItemByStateName($scope.stateName);

	$scope.state = {};

	$scope.selectMainMenuItem = function (item) {
		if (item.subMenu) {
			if (item.subMenu[0].states) {
				$scope.navbar.changeState(item.subMenu[0].states[0]);
			}
		} else if (item.states != undefined) {
			$scope.navbar.changeState(item.states[0]);
		}
	};

	$scope.unregisterShow = $rootScope.$on('showNavigation', function (event, data) {
		$scope.showNavigation = true;
	});

	$scope.unregisterHide = $rootScope.$on('hideNavigation', function (event, data) {
		$scope.showNavigation = false;
	});

	$scope.unregisterUserStatusChanged = $rootScope.$on('userStatusChanged', () => {
		loadActiveUser();
	});

	$scope.$on('$destroy', function () {
		//will be called on controller destroy
		$scope.unregisterHide();
		$scope.unregisterUserStatusChanged();
	});

	const loadActiveUser = () => {
		$scope.activeUser = UserService.getUser();
	};

	loadActiveUser();
}
;
