define('services/PermissionService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('PermissionService', ['$injector', PermissionService]);
});

function PermissionService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Permission');

	angular.extend(this, crudInstance);

	return this;
}
;
