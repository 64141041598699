define('modules/basemodule/controllers/EntityFormController',[
	'modules/module',
	'services/EntityCrudService',
	'services/NotificationService',
	'services/NavigationService',
	'services/UserService',
	'directives/Dialog/Dialog',
], function (module) {
	'use strict';
	module.controller('EntityFormController', [
		'$scope',
		'$rootScope',
		'$state',
		'$q',
		'$injector',
		'EntityCrudService',
		'NotificationService',
		'NavigationService',
		'ValidationService',
		'UserService',
		'InstallationService',
		FormController,
	]);
});

function FormController(
	$scope,
	$rootScope,
	$state,
	$q,
	$injector,
	EntityCrudService,
	NotificationService,
	NavigationService,
	ValidationService,
	UserService,
	InstallationService
) {
	$scope.CONTROLLER_ID = 'FormController';

	$scope.isClientNumber = InstallationService.isClientNumber;
	$scope.requestPlugins = InstallationService.requestPlugins;
	$scope.isPluginInstalled = InstallationService.isPluginInstalled;

	$scope.initialization = $q.defer();
	$scope.entityName = '';
	if (!$scope.preventInit) {
		$scope.entityId = $state.params.id; // Type is String
	}
	$scope.newEntity = true;
	$scope.selected = {};
	$scope.stateName = $state.current.name;
	$scope.productType = '';

	if (NavigationService.getActiveSubitem() != undefined) {
		$scope.headline = NavigationService.getActiveSubitem().label;
		$scope.single = NavigationService.getActiveSubitem().single;
	}
	$scope.locales = [];

	$scope.state = {
		hideUpdateButton: false,
		//TODO: buttons are flickering, moving initialization of this property before 'extend' should fix it
		requireSuperuserForUpdate: false,
	};

	$rootScope.$on('ShopChanged', function () {
		var states = NavigationService.getItemsRight();
		for (var i in states) {
			if (states[i].subMenu != undefined) {
				for (var j in states[i].subMenu) {
					if (states[i].subMenu[j].states != undefined) {
						for (var k in states[i].subMenu[j].states) {
							if (states[i].subMenu[j].states[k] == $scope.listState) {
								$scope.init($scope.entityName);
								return;
							}
						}
					}
				}
			}
		}
		if ($scope.entityId != '') {
			return;
		}
		$scope.init($scope.entityName);
	});
	// possibility to toggle action buttons from any point in app
	$rootScope.$on('EntityFormActionButtonsToggle', function (e, toggleFlag) {
		$scope.hideSaveButtons = !toggleFlag;
	});
	$scope.init = function (entity, entityService = null) {
		setHideButtons();

		if (!!entityService) {
			$scope.entityService = entityService;
		} else if (typeof entity === 'string') {
			$scope.entityService = EntityCrudService.getEntityInstance(entity);
			$scope.entityName = entity;
		} else {
			throw 'You have to pass either an entity type or service to the controller.';
		}

		$scope.initMenu();
		if ($scope.entityId) {
			if ($scope.entityName === 'Product') {
				if ($scope.stateName == 'root.productSimple' || $scope.stateName == 'root.productWizardSimple') {
					$scope.productType = 'simples';
				} else if ($scope.stateName == 'root.productConfig' || $scope.stateName == 'root.productWizardConfig') {
					$scope.productType = 'configurables';
				} else {
					$scope.productType = 'bundles';
				}
				$scope.entityService.findProduct($scope.entityId, $scope.productType).then(function () {
					$scope.entity = $scope.entityService.getOne();
					$scope.newEntity = false;
					$scope.initialization.resolve();
				});
			} else {
				$scope.entityService.findOne($scope.entityId).then(function () {
					$scope.entity = $scope.entityService.getOne();
					$scope.newEntity = false;
					$scope.initialization.resolve();
				});
			}
		} else {
			$scope.newEntity = true;
			$scope.entity = {};
			$scope.initialization.resolve();
		}

		$scope.initialization.promise.then(function () {
			$scope.$broadcast('afterInit');
		});

		return $scope.initialization.promise;
	};

	$scope.openWithProductWizard = () => {
		if ($scope.entity.productType === 'SIMPLE') {
			NavigationService.changeState('root.productWizardSimple', $scope.entity.id);
		} else if ($scope.entity.productType === 'CONFIGURABLE') {
			NavigationService.changeState('root.productWizardConfig', $scope.entity.id);
		} else {
			NavigationService.changeState('root.productWizardBundle', $scope.entity.id);
		}
	};

	$scope.initMenu = function () {
		$scope.formTabs.forEach(function (menuItem) {
			if (menuItem.active === true) {
				$scope.selected.menuItem = menuItem;
				$scope.selected.menuItem.active = true;
			}
		});
	};

	$scope.findMenuItemByName = function (name) {
		for (var i = 0; i < $scope.formTabs.length; i++) {
			if ($scope.formTabs[i].label === name) {
				return $scope.formTabs[i];
			}
		}
	};

	$scope.changeTab = function (menuItem) {
		if (typeof menuItem === 'string') {
			menuItem = $scope.findMenuItemByName(menuItem);
		}
		if ($scope.selected.menuItem !== undefined) {
			$scope.selected.menuItem.active = false;
		}
		menuItem.active = true;
		$scope.selected.menuItem = menuItem;
	};

	$scope.performUpdate = function (changeState, suppressNotification) {
		return $scope.entityService.update($scope.entity).then(function (response) {
			var type = $scope.entity.type;
			if (changeState) {
				NavigationService.changeState($scope.listState);
			}
			if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
				if (!suppressNotification) NotificationService.notifySuccess({ statusText: 'Erfolgreich gespeichert' });
				$scope.entity = response;

				$scope.entity.type = type;
				$scope.$broadcast('duplicateSaved');
				$scope.$broadcast('afterUpdate');
			} else {
				if (!suppressNotification) {
					NotificationService.notifyGeneralError({ statusText: 'Speichern fehlgeschlagen' });
				}

				$scope.$broadcast('updateError');
			}
		});
	};

	$scope.performCreate = function (changeState, suppressNotification) {
		return $scope.entityService.create($scope.entity).then(function (response) {
			var type = $scope.entity.type;
			if (changeState) {
				NavigationService.changeState($scope.listState);
			}
			if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
				if (!suppressNotification) NotificationService.notifySuccess({ statusText: 'Erfolgreich gespeichert' });
				$scope.newEntity = false;
				$scope.entity = response;

				$scope.entity.type = type;
				$scope.$broadcast('duplicateSaved');
				$scope.$broadcast('afterCreate');

				setHideButtons();
			} else {
				if (!suppressNotification) {
					NotificationService.notifyGeneralError({ statusText: response.error });
				}
				$scope.$broadcast('createError');
			}
		});
	};

	$scope.prepareSaveDelayed = function () {
		return $q(function (resolve, reject) {
			resolve();
		});
	};

	$scope.prepareCreateDelayed = function () {
		return $q(function (resolve, reject) {
			resolve();
		});
	};

	$scope.update = function (changeState, suppressNotification) {
		if (!$scope.validate()) {
			NotificationService.notifyValidationErrors(ValidationService.getErrors());
			return;
		}

		$scope.$broadcast('beforeUpdate');

		return $scope.prepareSaveDelayed().then(function () {
			return $scope.performUpdate(changeState, suppressNotification);
		});
	};

	$scope.create = function (changeState, suppressNotification) {
		if (!$scope.validate()) {
			NotificationService.notifyValidationErrors(ValidationService.getErrors());
			return;
		}

		$scope.$broadcast('beforeCreate');

		return $scope.prepareCreateDelayed().then(function () {
			return $scope.performCreate(changeState, suppressNotification);
		});
	};

	$scope.cancel = function () {
		NavigationService.changeState($scope.listState);
	};

	$scope.deleteEntityDialogOpen = false;

	$scope.deleteEntityClick = function () {
		$scope.deleteEntityDialogOpen = true;
	};

	$scope.confirmEntityDelete = () => {
		$scope.entityService.delete($scope.entity.id).then(() => {
			$scope.deleteEntityDialogOpen = false;
			NavigationService.changeState($scope.listState);
			NotificationService.notifySuccess({ statusText: 'Erfolgreich gelöscht' });
		});
	};

	$scope.validate = function () {
		if ($scope.validationConfig != undefined) {
			return ValidationService.validate($scope.entity, $scope.validationConfig);
		}

		return true;
	};

	const setHideButtons = () => {
		if ($scope.state.requireSuperuserForUpdate) {
			$scope.state.hideUpdateButton = !UserService.isSuperuser();
		} else {
			$scope.state.hideUpdateButton = false;
		}
	};
}
;
