define('services/InstallationService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('InstallationService', ['$injector', '$q', 'ModelFactory', InstallationService]);
});

function InstallationService($injector, $q, ModelFactory) {
	let version;

	let clientNumber;
	let clientNumberRequest;

	let plugins;
	let pluginsRequest;

	this.fetchVersion = function () {
		let deferred = $q.defer();
		ModelFactory.Installation()
			.version()
			.$promise.then(
				function (response) {
					version = response.value;
					deferred.resolve(version);
				},
				() => {}
			);
		return deferred.promise;
	};

	this.getVersion = function () {
		return version;
	};

	this.getClientNumber = function () {
		return clientNumber;
	};

	this.isClientNumber = function (check) {
		return check === clientNumber || '10' + check; // leading '10' indicates staging deployment
	};

	let fetchPlugins = (this.fetchPlugins = function () {
		let deferred = $q.defer();
		ModelFactory.Installation()
			.plugins()
			.$promise.then(
				function (response) {
					plugins = response;
					deferred.resolve(plugins);
				},
				() => {}
			);
		clientNumberRequest = deferred.promise;
		return deferred.promise;
	});

	this.requestPlugins = function (callback) {
		if (plugins !== undefined) {
			callback();
		} else if (!!pluginsRequest) {
			pluginsRequest.then(() => callback());
		} else {
			fetchPlugins().then(() => callback());
		}
	};

	this.getPlugins = function () {
		return plugins;
	};

	this.isPluginInstalled = function (check) {
		if (!plugins) {
			return false;
		}
		for (let plugin of plugins) {
			if (plugin.id === check) {
				return true;
			}
		}
	};

	// Known plugins
	this.beton2goPluginId = '3082817298595101757L';
	this.digitalProductsPluginId = '7710854808841553559';

	return this;
}
;
