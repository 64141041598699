define('modules/home/controllers/HomeController',[
	'modules/module',
	'services/DashboardService',
	'services/NavigationService',
	'directives/formatDate/formatDate',
], function (app, module) {
	'use strict';
	module.controller('HomeController', [
		'$scope',
		'$filter',
		'$rootScope',
		'DashboardService',
		'NavigationService',
		HomeController,
	]);
});
function HomeController($scope, $filter, $rootScope, DashboardService, NavigationService) {
	$scope.diagrammColors = [
		'#425217',
		'#95c11f',
		'#b7d761',
		'#e0eeba',
		'#a5cd39',
		'#6b8525',
		'#29330e',
		'#c9e188',
		'#8cae31',
		'#f2f8e1',
		'#242d0b',
		'#d1fc59',
		'#b2f104',
		'#92ad48',
		'#8baf28',
		'#e7fab2',
		'#4c6506',
		'#bdfec5',
		'#4e8d55',
		'#3ffd55',
		'#c3feca',
	];

	/*        // auto generating green shades
	 var max = 250;
	 var min = 150;
	 $scope.greenColors = [];
	 for(var i=0;i<=80;i++){
	 var green = Math.floor(Math.random() * (max - min + 1)) + min;

	 $scope.greenColors.push("#" + ((1 << min) + (max << 16) + (green << 9) + 0).toString(16).slice(1));

	 }*/

	$scope.selectedValues = [[]];
	$scope.selectedOptions = [];
	$scope.selectedSeries = [];
	$scope.selectedLabels = [];

	$scope.selectedOptions = {
		legend: {
			display: true,
		},
		scales: {
			yAxes: [
				{
					ticks: {
						min: 0,
					},
				},
			],
			xAxes: [
				{
					display: false,
				},
			],
		},
		elements: {
			line: {
				tension: 0,
			},
		},
	};

	$scope.revProfColors = ['#e0eeba', '#b7d761', '#95c11f', '#425217'];
	$scope.orderLineChartColors = ['#b7d761'];
	$scope.revProfData = [];
	$scope.ordersData = [];
	$scope.revProfSeries = ['Umsatz', 'Materialkosten', 'Rohertrag'];
	$scope.ordersSeries = ['Orders'];
	var options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };

	//$scope.datasetOverride = [{ yAxisID: 'y-axis-1' }, { yAxisID: 'y-axis-2' }];

	/*	$scope.datasetOverride = [{
	 steppedLine: true,
	 fill: false
	 }];*/
	$scope.revProfSelectedOptions = {
		scales: {
			xAxes: [
				{
					display: false,
				},
			],
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					display: true,
					//position: 'left'
				},
				{
					id: 'y-axis-2',
					type: 'linear',
					display: false,
					//position: 'right'
				},
				{
					id: 'y-axis-3',
					type: 'linear',
					display: false,
					//position: 'right'
				},
			],
		},
		elements: {
			line: {
				tension: 0,
			},
		},
		/*scaleLabel: (label) => $filter('currency')(label.value),
		tooltipTemplate: (data) => ${data.label}: ${$filter('currency')(data.value)}*/

		tooltips: {
			enabled: true,
			callbacks: {
				label: function (tooltipItem, data) {
					var label = data.datasets[tooltipItem.datasetIndex].label;
					var datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					return label + ': ' + $filter('currency')(datasetLabel);
				},

				title: function (tooltipItem, data) {
					var label = tooltipItem[0].xLabel;
					var from = label.split('-');
					var f = new Date(from[2], from[1] - 1, from[0]).toLocaleDateString('de-DE', options);
					return f;
				},
			},
		},
	};

	$scope.orderLineChartOptions = {
		scales: {
			xAxes: [
				{
					display: true,
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					display: true,

					//position: 'left'
				},
			],
		},
		elements: {
			line: {
				tension: 0,
			},
		},
	};

	$scope.selectedItem = '';

	$scope.dashboard = {};
	$scope.profit = {};
	$scope.revenueData = [];

	$scope.date = {
		start: new Date(new Date().setDate(new Date().getDate() - 14)),
		end: new Date(new Date().setDate(new Date().getDate())),
	};

	$scope.calcMaterialCost = function (revenue, profit) {
		var materialCost = [];

		for (var i = 0; i < revenue.length; i++) {
			materialCost.push(revenue[i] - profit[i]);
		}

		return materialCost;
	};

	$scope.getDashboard = function (date) {
		$scope.revProfData.length = 0;
		$scope.ordersData.length = 0;

		if (date == undefined) {
			date = {
				start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
				end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
			};
		}
		var monthStart = date.start.getMonth() + 1;
		var monthEnd = date.end.getMonth() + 1;

		var dateObj = {};
		dateObj.start = date.start.getFullYear() + '-' + monthStart + '-' + date.start.getDate();
		dateObj.end = date.end.getFullYear() + '-' + monthEnd + '-' + date.end.getDate();

		$scope.date = date;

		DashboardService.setAdditionalParams([
			{ name: 'startDate', value: dateObj.start },
			{ name: 'endDate', value: dateObj.end },
		]);
		DashboardService.findAll(true).then(function () {
			$scope.dashboard = DashboardService.getAll();
			//$scope.setSelectedDiagram('revenue');
			$scope.revenueData.push($scope.dashboard.revenue.series);
			$scope.revProfData.push($scope.dashboard.revenue.series);
			$scope.revProfData.push($scope.dashboard.profit.data[0]);
			$scope.ordersData.push($scope.dashboard.orders.series);
			$scope.revProfData.push(
				$scope.calcMaterialCost($scope.dashboard.revenue.series, $scope.dashboard.profit.data[0])
			);
		});
	};

	$scope.getProfit = function (date) {
		$scope.revProfData.length = 0;

		if (date == undefined) {
			date = {
				start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
				end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
			};
		}
		var monthStart = date.start.getMonth() + 1;
		var monthEnd = date.end.getMonth() + 1;

		var dateObj = {};
		dateObj.start = date.start.getFullYear() + '-' + monthStart + '-' + date.start.getDate();
		dateObj.end = date.end.getFullYear() + '-' + monthEnd + '-' + date.end.getDate();

		$scope.date = date;
		DashboardService.setAdditionalParams([
			{ startDate: dateObj.start },
			{ name: 'endDate', value: dateObj.end },
			{ name: 'profit', value: true },
		]);
		DashboardService.findAll(true).then(function () {
			$scope.newData = DashboardService.getAll();
			$scope.dashboard.profit = $scope.newData.profit;
			$scope.revenueData.push($scope.newData.revenue.series);
			$scope.revProfData.push($scope.newData.revenue.series);
			$scope.revProfData.push($scope.newData.profit.data[0]);
			$scope.revProfData.push(
				$scope.calcMaterialCost($scope.dashboard.revenue.series, $scope.dashboard.profit.data[0])
			);
		});
	};

	$scope.profit.colors = ['#425217', '#95c11f', '#b7d761', '#e0eeba'];
	$scope.profit.options = {
		legend: {
			display: true,
		},
		scales: {
			xAxes: [
				{
					stacked: true,
					display: false,
				},
			],
			yAxes: [
				{
					stacked: true,
				},
			],
		},
	};

	$scope.noLabelOptions = {
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					display: false,
				},
			],
		},
	};

	$scope.getDashboard($scope.date);

	$rootScope.$on('getDashboard', function (event, data) {
		$scope.getDashboard(data);
	});

	$rootScope.$on('getProfit', function (event, data) {
		$scope.getProfit(data);
	});

	/*
	 Modal Dialog functions
	 */
	$scope.onSuccess = function (result) {};

	$scope.onCancel = function () {};

	$scope.dialogHomeController = function ($scope, dataToPass, $mdDialog, $rootScope) {
		$scope.date = {
			start: dataToPass.start,
			end: dataToPass.end,
		};

		$scope.data = dataToPass;
		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.getDashboard = function () {
			$rootScope.$emit('getDashboard', $scope.date);
			$rootScope.$emit('closeModal');
		};
	};

	$scope.dialogProfitController = function ($scope, dataToPass, $mdDialog, $rootScope) {
		$scope.date = {
			start: dataToPass.start,
			end: dataToPass.end,
		};

		$scope.data = dataToPass;
		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.getProfit = function (date) {
			$rootScope.$emit('getProfit', $scope.date);
			$rootScope.$emit('closeModal');
		};
	};

	$scope.showMore = function (reportType) {
		var obj = { startDate: $scope.date.start, endDate: $scope.date.end, reportType: reportType };
		NavigationService.changeStateWithObject('root.reports', obj);
	};
}
;
