define('modules/econ/controllers/EconRecipientFormController',[
	'modules/module',
	'settings',
	'services/EconRecipientService',
	'services/EconRecipientListService',
	'services/EconMailtemplateService',
], function (module) {
	'use strict';
	module.controller('EconRecipientFormController', [
		'$scope',
		'$controller',
		'EconRecipientService',
		'EconRecipientListService',
		'EconMailtemplateService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		RecipientFormController,
	]);
});

function RecipientFormController(
	$scope,
	$controller,
	EconRecipientService,
	EconRecipientListService,
	EconMailtemplateService,
	NavigationService,
	BASE_TEMPLATES_PATH
) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-recipient-main.html',
			active: true,
		},
		{
			label: 'FIELDS.RECIPIENT_LIST',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-recipient-recipient-list.html',
		},
	];

	$scope.validationConfig = {
		email: {
			method: 'isset',
			fieldName: 'E-Mail',
		},
		status: {
			method: 'isset',
			fieldName: 'Status',
		},
	};

	$scope.listState = 'root.econRecipients';

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	$scope.statuses = [
		{ name: 'Opt In angefordert (0)', value: 'NO_OPT_IN_SENT' },
		{ name: 'Opt In versandt (1)', value: 'OPT_IN_SENT' },
		{ name: 'Opt In bestätigt (2)', value: 'SUBSCRIBED' },
		{ name: 'Deaktiviert (3)', value: 'UNSUBSCRIBED' },
		{ name: 'Bounced (4)', value: 'BOUNCED' },
	];

	$scope.init(null, EconRecipientService).then(function () {
		$scope.selectedTemplate = null;
		$scope.mailTemplates = null;

		EconMailtemplateService.findAll(true).then(function () {
			$scope.mailTemplates = EconMailtemplateService.getAll();
		});

		initRecipientLists();

		$scope.beforeUpdateCreate = function () {
			$scope.entity.recipientLists = [];
			$scope.recipientLists.forEach(function (recipientList) {
				if (recipientList.selected) {
					//do not send all recipients
					recipientList.recipients = null;
					$scope.entity.recipientLists.push(recipientList);
				}
				delete recipientList.selected;
				delete recipientList.recipients;
				delete recipientList.optinTemplate;
				delete recipientList.unsubscribeTemplate;
			});
		};

		$scope.$on('beforeUpdate', function () {
			$scope.beforeUpdateCreate();
		});

		$scope.$on('beforeCreate', function () {
			$scope.beforeUpdateCreate();
		});

		$scope.$on('afterCreate', function () {
			initRecipientLists();
		});

		$scope.$on('afterUpdate', function () {
			initRecipientLists();
		});
	});

	$scope.sendTemplate = function () {
		if ($scope.selectedTemplate == null) {
			return;
		}
		EconMailtemplateService.send($scope.selectedTemplate.id, $scope.entity.id).then(function (data) {
			if (data.success == true) {
				alert('E-Mail erfolgreich versandt!');
			}
		});
	};

	$scope.selectedTemplateChanged = function () {
		$scope.selectedTemplate = this.selectedItem;
	};

	function initRecipientLists() {
		EconRecipientListService.findAll().then(function () {
			$scope.recipientLists = EconRecipientListService.getAll();
			$scope.recipientLists.forEach(function (recipientList) {
				recipientList.selected = false;
			});
			if (!$scope.newEntity) {
				$scope.entity.recipientLists.forEach(function (selectedRecipientList) {
					$scope.recipientLists.forEach(function (recipientList) {
						if (selectedRecipientList.id == recipientList.id) {
							recipientList.selected = true;
						}
					});
				});
			}
		});
	}
}
;
