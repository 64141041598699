define('directives/infinity/Infinity',['app'], function (app) {
	'use strict';
	app.directive('ngInfinity', [
		'$translate',
		'$rootScope',
		function ($translate, $rootScope) {
			return {
				require: 'ngModel',
				link: function ($scope, element, attrs, modelCtrl) {
					//convert data from view format to model format
					$scope.currentLocale = $translate.use();
					$scope.infinity = '';

					$rootScope.$on('$translateChangeSuccess', function (e, { language }) {
						$scope.currentLocale = language;
					});

					function isInfinityValue(value) {
						return value < 0 || isNaN(parseInt(value));
					}

					$scope.$watch('currentLocale', function (newValue, oldValue) {
						if (!newValue) {
							return;
						}

						switch (newValue) {
							case 'de':
								$scope.infinity = 'Beliebig';
								break;
							case 'en':
								$scope.infinity = 'Infinity';
								break;
							case 'fr':
								$scope.infinity = 'et au-delà';
								break;
							case 'cz':
								$scope.infinity = 'Jakýkoli';
								break;
							default:
								$scope.infinity = 'Infinity';
						}

						if (attrs.useInfinity && isInfinityValue(modelCtrl.$viewValue)) {
							const modelValueSetter = modelCtrl.$$parse(attrs.ngModel).assign;
							modelValueSetter($scope, $scope.infinity);
						}
					});

					modelCtrl.$parsers.push(function (inputValue) {
						var transformedInput;
						if (inputValue == $scope.infinity) {
							transformedInput = '-1';
						} else {
							transformedInput = inputValue;
						}

						return transformedInput;
					});

					//convert data from model format to view format
					modelCtrl.$formatters.push(function (data) {
						var transformedInput;
						if (data == '-1') {
							transformedInput = $scope.infinity;
						} else {
							transformedInput = data;
						}

						/*if (transformedInput != inputValue) {
						 modelCtrl.$setViewValue(transformedInput);
						 modelCtrl.$render();
						 }*/

						return transformedInput;
					});
				},
			};
		},
	]);
});

