define('modules/settings/importexportcsv/ImportExportCSVController',[
	'modules/module',
	'services/ImportExportService',
	'services/NotificationService',
	'services/SessionValuesService',
	'services/LocaleService',
	'services/ValidationService',
	'directives/fileModel/FileModel',
], function (module) {
	'use strict';
	module.controller('ImportExportCSVController', [
		'$scope',
		'ImportExportService',
		'NotificationService',
		'SessionValuesService',
		'LocaleService',
		'ValidationService',
		'BASE_TEMPLATES_PATH',
		'$window',
		'$http',
		function (
			$scope,
			ImportExportService,
			NotificationService,
			SessionValuesService,
			LocaleService,
			ValidationService,
			BASE_TEMPLATES_PATH,
			$window,
			$http
		) {
			$scope.recipientsCSV = null;

			$scope.formTabs = [
				{
					label: 'FIELDS.IMPORT',
					template: BASE_TEMPLATES_PATH + '/settings/importExport/import.html',
					active: true,
				},
				{
					label: 'FIELDS.EXPORT',
					template: BASE_TEMPLATES_PATH + '/settings/importExport/export.html',
				},
			];

			$scope.selected = {};

			$scope.findMenuItemByName = function (name) {
				for (var i = 0; i < $scope.formTabs.length; i++) {
					if ($scope.formTabs[i].label === name) {
						return $scope.formTabs[i];
					}
				}
			};

			$scope.changeTab = function (menuItem) {
				if (typeof menuItem === 'string') {
					menuItem = $scope.findMenuItemByName(menuItem);
				}
				if ($scope.selected.menuItem !== undefined) {
					$scope.selected.menuItem.active = false;
				}
				menuItem.active = true;
				$scope.selected.menuItem = menuItem;
			};

			$scope.initMenu = function () {
				$scope.formTabs.forEach(function (menuItem) {
					if (menuItem.active === true) {
						$scope.selected.menuItem = menuItem;
						$scope.selected.menuItem.active = true;
					}
				});
			};

			$scope.initMenu();

			$scope.transferCSV = function () {
				ImportExportService.importRecipientsCSV($scope.recipientsCSV).then(function (data) {
					if (data.validationErrors) {
						return;
					}
					NotificationService.notifySuccess({ statusText: 'Der Import war erfolgreich' });
					$scope.recipientsCSV = null;
				});
			};

			$scope.fileSelected = function () {
				console.log('File selected: ', $scope.recipientsCSV);
				// perform additional actions here
			};

			$scope.exportCSV = function () {
				$http({
					url: '/api/v1/core/import-export/export-recipients-csv',
					method: 'GET',
					headers: SessionValuesService.getFullSessionHeader(),
					responseType: 'text/csv'
				}).then(function(response) {
					let blob = new Blob([response.data], {type: response.headers()['content-type']});
					let url = URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = response.headers()['content-disposition'];
					a.click();
				});

			};
		},
	]);
});

