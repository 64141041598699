define('modules/econ/controllers/EconRecipientListFormController',[
	'modules/module',
	'settings',
	'directives/entityListSelect/EntityListSelect',
	'services/PublishStateService',
	'services/EconRecipientListService',
	'services/EconRecipientService',
	'services/EconMailtemplateService',
	'services/NavigationService',
	'services/NotificationService',
], function (module) {
	'use strict';
	module.controller('EconRecipientListFormController', [
		'$scope',
		'$controller',
		'EconRecipientListService',
		'EconRecipientService',
		'NavigationService',
		'NotificationService',
		'EconMailtemplateService',
		'PublishStateService',
		'BASE_TEMPLATES_PATH',
		EconRecipientListFormController,
	]);
});

function EconRecipientListFormController(
	$scope,
	$controller,
	EconRecipientListService,
	EconRecipientService,
	NavigationService,
	NotificationService,
	EconMailtemplateService,
	PublishStateService,
	BASE_TEMPLATES_PATH
) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-recipient-list-main.html',
			active: true,
		},
		{
			label: 'FIELDS.RECIPIENT',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-recipient-list-recipients.html',
		},
	];

	$scope.colors = [
		{ display: 'Rot', value: 'red' },
		{ display: 'Orange', value: 'orange' },
		{ display: 'Blau', value: 'blue' },
		{ display: 'Grün', value: 'green' },
		{ display: 'Rot', value: 'red' },
		{ display: 'Violett', value: 'BlueViolet' },
		{ display: 'Cyan', value: 'Cyan' },
		{ display: 'Türkis', value: 'DarkTurquoise ' },
	];

	$scope.validationConfig = {
		name: {
			method: 'isset',
			fieldName: 'FIELDS.LIST_NAME',
		},
		color: {
			method: 'isset',
			fieldName: 'Farbe',
		},
		shortcut: {
			method: 'isset',
			fieldName: 'Kürzel',
		},
		optinTemplateId: {
			method: 'isset',
			fieldName: 'Opt-In Template',
		},
		unsubscribeTemplateId: {
			method: 'isset',
			fieldName: 'Abmelde Template',
		},
	};

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	$scope.listState = 'root.econRecipientLists';
	$scope.recipientTableOptions = {
		name: 'NAVIGATION.RECIPIENT',
		columns: [
			{
				id: 'FIRST_NAME',
				heading: 'FIELDS.FIRSTNAME',
				attribute: 'firstName',
			},
			{
				id: 'LAST_NAME',
				heading: 'FIELDS.LASTNAME',
				attribute: 'lastName',
			},
			{
				id: 'EMAIL',
				heading: 'FIELDS.EMAIL',
				attribute: 'email',
			},
		],
	};

	$scope.selected = {};
	$scope.selected.recipients = [];
	$scope.assignedRecipients = [];
	$scope.publishStates = [];
	$scope.econRecipientService = EconRecipientService;
	$scope.econRecipientListService = EconRecipientListService;
	$scope.single = NavigationService.getActiveSubitem().single;
	$scope.recipientsTable = {};

	$scope.init('RecipientList', EconRecipientListService).then(function () {
		$scope.initRecipientTable();
		$scope.getOnlyAssignedRecipients();
		EconMailtemplateService.findAll(true).then(function () {
			$scope.mailTemplates = EconMailtemplateService.getAll();
		});

		PublishStateService.findAll().then(function () {
			$scope.publishStates = PublishStateService.getAll();
		});
	});
	$scope.initRecipientTable = function () {
		$scope.recipientsTable = {
			query: {
				filters: {},
			},
			paging: {
				page: 1,
				size: 10,
				totalItems: 0,
				totalPages: 0,
			},
			filters: {},
		};
	};

	$scope.getOnlyAssignedRecipients = function () {
		if (!$scope.newEntity) {
			$scope.getAssignedRecipients();
		} else {
			$scope.entity.showInFrontend = true;
		}
	};
	$scope.$on('filtersChanges', function (e, filters, sorting) {
		console.log(JSON.stringify(filters) + '  =  ' + JSON.stringify($scope.recipientsTable.query.filters));
		if (JSON.stringify(filters) !== JSON.stringify($scope.recipientsTable.query.filters)) {
			$scope.recipientsTable.query.filters = JSON.parse(JSON.stringify(filters));
			$scope.getAssignedRecipients();
		}
	});
	$scope.getAssignedRecipients = function () {
		var paging = {
			page: $scope.recipientsTable.paging.page - 1,
			size: $scope.recipientsTable.paging.size,
		};
		$scope.econRecipientService
			.findByRecipientListId($scope.entity.id, paging, $scope.recipientsTable.query.filters)
			.then(function (response) {
				$scope.assignedRecipients = response.data;
				$scope.recipientsTable.paging.totalItems = response.count;
				$scope.recipientsTable.paging.totalPages = response.pages;
				$scope.recipientsTable.paging.page = response.page + 1;
				$scope.$broadcast('tableDataRefreshed');
			});
	};
	$scope.removeRecipientFromRecipientList = function () {
		var request = {};
		if ($scope.selected.allSelectedOnAllPages) {
			request = JSON.parse(JSON.stringify($scope.recipientsTable.query.filters));
			request.useFilter = true;
		} else {
			var recipientListIds = [];
			if ($scope.selected.recipients && $scope.selected.recipients.length > 0) {
				$scope.selected.recipients.forEach((rec) => {
					recipientListIds.push(rec.id);
				});
				request.useFilter = false;
				request.recipientIds = recipientListIds;
			} else {
				return;
			}
		}
		request.recipientListId = $scope.entity.id;
		$scope.econRecipientListService.removeRecipientsFromRecipientList(request).then(function (response) {
			if (response.error == undefined && response.errorCode == undefined && response.ok != false) {
				NotificationService.notifySuccess({ statusText: 'recipients has been removed from recipientList' });
				$scope.getAssignedRecipients();
			} else {
				NotificationService.notifyGeneralError({ statusText: 'Speichern fehlgeschlagen' });
			}
		});
	};

	$scope.showCurrentlyDisplayed = function () {
		let from = 0;
		let to = 0;

		if ($scope.assignedRecipients && $scope.assignedRecipients.length > 0) {
			from = parseInt($scope.recipientsTable.paging.size) * parseInt($scope.recipientsTable.paging.page - 1);
			to = Math.min(
				from + parseInt($scope.recipientsTable.paging.size),
				$scope.recipientsTable.paging.totalItems
			);

			// Because 'from' starts at 0
			from++;
		}

		return from + ' - ' + to;
	};
}
;
