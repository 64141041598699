define('directives/controls/customCheckbox/CustomCheckbox',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngCustomCheckbox', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					label: '@',
					options: '=',
					result: '=',
					placeholder: '@',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/controls/customCheckbox/views/CustomCheckbox.html',
				controller: [
					'$scope',
					function ($scope) {
						//console.log("TEST TEST TEST");
						//console.log('OPTIONS');
						//console.log($scope.options);
					},
				],
			};
		},
	]);
});

