define('settings',['app'], function (app) {
	'use strict';

	const ROLE_ADMIN = 'ROLE_ADMIN';
	const ROLE_ECONNECT = 'ROLE_ECONNECT';
	const ROLE_SUPERUSER = 'ROLE_SUPERUSER';

	return app
		.constant('BASE_DIRECTIVES_PATH', '/app/directives')
		.constant('BASE_MODULES_PATH', '/app/modules')
		.constant('BASE_TEMPLATES_PATH', '/template')
		.constant('EU_COUNTRIES', [
			'AUT',
			'BEL',
			'BGR',
			'HRV',
			'CYP',
			'CZE',
			'DNK',
			'EST',
			'FIN',
			'FRA',
			'DEU',
			'GRC',
			'HUN',
			'IRL',
			'ITA',
			'LVA',
			'LTU',
			'LUX',
			'MLT',
			'NLD',
			'POL',
			'PRT',
			'ROU',
			'SVK',
			'SVN',
			'ESP',
			'SWE',
			'GBR',
		])
		.constant('MENU_LEFT', [
			{
				identifier: 'ECON',
				label: 'NAVIGATION.ECON', // exact value is used in LoginController
				icon: 'fa-envelope',
				requiredRole: ROLE_ECONNECT,
				subMenu: [
					{
						label: 'NAVIGATION.DASHBOARD',
						icon: 'fa-tachometer',
						states: ['root.econDashboard'],
						single: 'Dashboard',
					},
					{
						label: 'NAVIGATION.RECIPIENT',
						icon: 'fa-user',
						states: ['root.econRecipients', 'root.econRecipient'],
						single: 'Empfänger',
					},
					{
						label: 'NAVIGATION.RECIPIENT_LIST',
						icon: 'fa-users',
						states: ['root.econRecipientLists', 'root.econRecipientList'],
						single: 'Empfängerliste',
					},
					{
						label: 'NAVIGATION.TEMPLATES',
						icon: 'fa-file-code-o',
						states: ['root.econMailtemplates', 'root.econMailtemplate'],
						single: 'Newslettervorlage',
					},
					{
						label: 'NAVIGATION.NEWSLETTER',
						icon: 'fa-file-text',
						states: ['root.econNewsletters', 'root.econNewsletter'],
						single: 'Newsletter',
					},
					/*{
						label: 'NAVIGATION.AUTOMATION',
						icon: 'fa-magic',
						states: ['root.econAutomations', 'root.econAutomation'],
						single: 'Automatisierung',
					},*/
					{
						label: 'NAVIGATION.CAMPAIGNS',
						icon: 'fa-caret-square-o-right',
						states: ['root.econCampaigns', 'root.econCampaign'],
						single: 'Kampagne',
					},
				],
			},
		])
		.constant('MENU_RIGHT', [
			{
				identifier: 'SHOP_SELECTOR',
				label: 'Shopauswahl',
				icon: 'fa-home',
				requiredRole: ROLE_ADMIN,
			},
			{
				identifier: 'SETTINGS',
				label: 'NAVIGATION.SETTINGS',
				icon: 'fa-gears',
				subMenu: [
					{
						label: 'NAVIGATION.USER',
						icon: 'fa-user',
						requiredRole: ROLE_ADMIN,
						states: ['root.users', 'root.user'],
						single: 'SINGLE.USER',
					},
					{
						label: 'NAVIGATION.IMPORT_EXPORT',
						icon: 'fa-arrow-circle-up',
						requiredRole: ROLE_ADMIN,
						states: ['root.importexportcsv'],
						single: 'SINGLE.IMPORT_EXPORT',
					},
					{
						label: 'NAVIGATION.ECON',
						icon: 'fa-envelope',
						requiredRole: ROLE_ECONNECT,
						states: ['root.econSettings', 'root.econSettings'],
						single: 'SINGLE.ECON',
					},
				],
			},
			{
				identifier: 'LOGOUT',
				label: 'NAVIGATION.LOGOUT',
				icon: 'fa-sign-out',
				states: ['root.login'],
			},
		])
		.constant('PRODUCT_FILTER_OPTIONS', [
			{
				id: 'productNumber',
				name: 'Artikelnummer',
				types: [
					{
						id: 'equals',
						type: 'ist gleich',
					},
					{
						id: 'contains',
						type: 'enthält',
					},
				],
			},
			{
				id: 'productId',
				name: 'Produkt Id',
				types: [
					{
						id: '<=',
						type: '<=',
					},
					{
						id: '>=',
						type: '>=',
					},
					{
						id: '=',
						type: '=',
					},
				],
			},
			{
				id: 'productTitle',
				name: 'Artikelbezeichnung',
				types: [
					{
						id: 'contains',
						type: 'enthält',
					},
					{
						id: 'equals',
						type: 'ist gleich',
					},
					{
						id: 'startsWith',
						type: 'beginnt mit',
					},
					{
						id: 'endsWith',
						type: 'endet mit',
					},
				],
			},
			{
				id: 'productPrice',
				name: 'Artikelpreis',
				types: [
					{
						id: '<=',
						type: '<=',
					},
					{
						id: '>=',
						type: '>=',
					},
					{
						id: '=',
						type: '=',
					},
				],
			},
			{
				id: 'productMetaTag',
				name: 'Artikelmerkmal',
				types: [
					{
						id: 'contains',
						type: 'enthält',
					},
					{
						id: 'notContains',
						type: 'enthält nicht',
					},
				],
			},
			{
				id: 'productAttribute',
				name: 'Artikelattribut',
				types: [
					{
						id: 'contains',
						type: 'enthält',
					},
					{
						id: 'notContains',
						type: 'enthält nicht',
					},
				],
			},
			{
				id: 'productCategory',
				name: 'Kategorie',
				types: [
					{
						id: 'equals',
						type: 'ist gleich',
					},
				],
			},
			{
				id: 'productFlag',
				name: 'Markierung',
				types: [
					{
						id: 'equals',
						type: 'ist gleich',
					},
					{
						id: 'notEquals',
						type: 'ist nicht gleich',
					},
					{
						id: 'notNull',
						type: 'ist markiert',
					},
					{
						id: 'null',
						type: 'ist nicht markiert',
					},
				],
			},
		])
		.constant('PRIMARKETS_EVENTS', [
			{
				type: 'SYSTEM_EVENT',
				title: 'User Password Reset Before',
				key: 'USER_PASSWORD_RESET_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Password Reset Before',
				key: 'PASSWORD_RESET_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Password Reset After',
				key: 'PASSWORD_RESET_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Reset Email Before',
				key: 'EMAIL_RESET_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Reset Email After',
				key: 'EMAIL_RESET_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Email Activation Before',
				key: 'EMAIL_ACTIVATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Email Activation After',
				key: 'EMAIL_ACTIVATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Customer Before',
				key: 'TICKET_TO_CUSTOMER_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Customer After',
				key: 'TICKET_TO_CUSTOMER_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Admin Before',
				key: 'TICKET_TO_ADMIN_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Admin After',
				key: 'TICKET_TO_ADMIN_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Organisation Invitation Before',
				key: 'ORGANISATION_INVITATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Organisation Invitation After',
				key: 'ORGANISATION_INVITATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Account Activation Before',
				key: 'ACCOUNT_ACTIVATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Account Activation After',
				key: 'ACCOUNT_ACTIVATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Pending Account Activation Before',
				key: 'PENDING_ACCOUNT_ACTIVATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Pending Account Activation After',
				key: 'PENDING_ACCOUNT_ACTIVATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Drop Shipping Organisation Before',
				key: 'DROP_SHIPPING_ORGANISATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Drop Shipping Organisation AFTER',
				key: 'DROP_SHIPPING_ORGANISATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to User Before',
				key: 'DOCUMENT_NOTIFICATION_USER_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to User After',
				key: 'DOCUMENT_NOTIFICATION_USER_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to Admin Before',
				key: 'DOCUMENT_NOTIFICATION_ADMIN_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to Admin After',
				key: 'DOCUMENT_NOTIFICATION_ADMIN_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Shop order cancelled after',
				key: 'SHOP_ORDER_CANCELED_AFTER',
			},
		])
		.constant('LOCALES', [
			{ id: 1, isDefault: true, language: 'LOCALES.DE', locale: 'de' },
			{ id: 2, isDefault: false, language: 'LOCALES.EN', locale: 'en' },
			{ id: 3, isDefault: false, language: 'LOCALES.FR', locale: 'fr' },
			{ id: 4, isDefault: false, language: 'LOCALES.CZ', locale: 'cz' },
		]);
});

