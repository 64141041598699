define('modules/image/controllers/ImageUploadController',['modules/module', 'modules/models', 'directives/thumbnail/thumbnail', 'services/ImageService'], function (
	module
) {
	'use strict';
	module.controller('ImageUploadController', [
		'$scope',
		'FileUploader',
		'ImageService',
		function ($scope, FileUploader, ImageService) {
			$scope.imageAttributes = {};

			$scope.uploader = new FileUploader({
				url: '/api/v1/image-upload',
			});

			$scope.loadImages = function () {
				ImageService.loadImages().then(function () {
					$scope.images = ImageService.getImages();
				});
			};

			$scope.uploadImage = function (item) {
				ImageService.saveImage({ displayName: item.data.displayName }).then(function () {
					delete item.data.displayName;
					item.data.imageId = ImageService.getImage().id;
					item.data.screenDpi = 100;
					$scope.imageAttributes[ImageService.getImage().id] = angular.copy(item.data);
					item.upload();
				});
			};

			$scope.uploader.onBeforeUploadItem = function (item) {
				item.formData.push($scope.imageAttributes[item.data.imageId]);
				delete item.data;
			};

			$scope.deleteImage = function (imageId) {
				ImageService.deleteImage(imageId).then($scope.loadImages);
			};

			$scope.uploader.filters.push({
				name: 'imageFilter',
				fn: function (item /*{File|FileLikeObject}*/, options) {
					var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
					return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
				},
			});

			$scope.uploadViaInput = function () {
				$('#file-input').click();
			};

			$scope.getType = function (image) {
				return image.characteristics[0].aspectRatio;
			};

			$scope.getImageSrc = function (image) {
				return image.characteristics[0].uri;
			};

			$scope.loadImages();
		},
	]);
});

